$green: #34c759;
$white: #ffffff;
$light-grey: #f6f7f7;
$grey: #cccccc;
$dark-grey: #353d47;
$red: #ff3b30;
$matte-red: rgba(255, 59, 48, 0.6);

$light-grey-text: rgba(30, 39, 50, 0.4);
$medium-grey-text: rgba(30, 39, 50, 0.6);
$dark-grey-text: rgba(30, 39, 50, 0.8);

@mixin input-item-shadows {
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
    0px 0px 0px 0px rgba(30, 39, 50, 0.04),
    inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);
  transition: box-shadow 500ms;
}

@mixin input-item {
  padding: 8px clamp(8px, calc(0.5rem + ((1vw - 3.2px) * 0.5882)), 12px);
  font-size: 16px;
  line-height: 24px;
}

@mixin input-item-hovered {
  box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
    0px 0px 0px 4px rgba(30, 39, 50, 0.04),
    inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);

}

@mixin input-item-focused {
  box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.24),
    0px 0px 0px 4px rgba(30, 39, 50, 0.08),
    inset 0px 0px 0px 1px rgba(30, 39, 50, 0.24);
}

@mixin input-item-error {
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px $matte-red,
    0px 0px 0px 0px rgba(255, 59, 48, 0.1),
    inset 0px 0px 0px 1px $matte-red;
  transition: box-shadow 500ms;
}

@mixin input-item-error-hovered {
  box-shadow: 0px 0px 0px 1px $matte-red,
    0px 0px 0px 4px rgba(255, 59, 48, 0.1),
    inset 0px 0px 0px 1px $matte-red;

}

@mixin input-item-error-focused {
  box-shadow: 0px 0px 0px 1px $red,
    0px 0px 0px 4px rgba(255, 59, 48, 0.2),
    inset 0px 0px 0px 1px $red;
}

@mixin selector {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  @include input-item-shadows;
}