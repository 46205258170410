.table{
  table{
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  .ant-table-row{
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      box-shadow: 0px 10px 15px 0px rgba(173, 173, 173, 0.5);
      & > td {
        background: $light-grey !important;
      }
    }
    &>td {
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;

      &:first-child {
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          border-left: 1px solid $grey  !important;
        }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        border-right: 1px solid $grey;
      }
    }
  }
  .ant-table-row-expand-icon-cell {
    padding: 0;
  }

  &-expandable{
    .ant-table-row {
      & > td {
        &:first-child{
          border: none !important;
        }
        &:nth-child(2) {
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          border-left: 1px solid $grey;
        }
      }
    }
  }
}