@import './theme.scss';

.ant-select {
  &-selector {
    @include selector;

    &:hover {
      @include input-item-hovered;
    }
  }

  &-focused {
    .ant-select-selector {
      @include input-item-focused;
    }
  }

  &-selection-item {
    color: black;
  }
}