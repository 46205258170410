.ant-layout-header{
  z-index: 1;
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  position: absolute;
  padding: 16px clamp(20px, calc(1.25rem + ((1vw - 2.8px) * 1.5152)), 40px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  .logo {
    font-weight: bold;
    font-size: 22px;
    color: black
  }

  background: white;
  box-shadow: 0px 0.5px 0px rgba(30, 39, 50, 0.12),
      0px 4px 0px rgba(30, 39, 50, 0.04),
      inset 0px -0.5px 0px rgba(30, 39, 50, 0.12);
}