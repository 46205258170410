@import './theme.scss';

.upload{
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 2px;
  background-color: $white;
  border: 1px dashed #d9d9d9;
  transition: border-color .3s;

  &--medium{
    width: 500px;
    margin: 25px 0;
    padding: 15px 0;
  }

  &-icon{
    color: $green;
    font-size: 48px;
  }

  &:hover {
    border: 1px dashed $green;
  }
  .ant-upload{
    width: 100%;
  }
}