@import './theme.scss';
.ant-btn-primary {
  border-color: $green;
  background-color: $green;
  &:hover {
    // border-color: $green;
    // background-color: $green;
    border-color: #42cf65;
    background-color: #42cf65;
  }
}
